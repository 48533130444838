import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Table, TableBody, TableCell, TableHead, TableRow, Paper, Typography } from '@mui/material';

const Admin = () => {
  const [bauteile, setBauteile] = useState([]);
  const [formData, setFormData] = useState({
    id: '',
    bezeichnung: '',
    min_drehmoment: '',
    max_drehmoment: '',
    kategorie: '',
    type: ''
  });

  const fetchBauteile = async () => {
    const response = await fetch('https://gt6.at/get_bauteile.php');
    const data = await response.json();
    setBauteile(data);
  };

  useEffect(() => {
    fetchBauteile();
  }, []);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const action = formData.id ? 'update' : 'create';
    await fetch(`https://gt6.at/admin/manage_bauteile.php?action=${action}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    });
    setFormData({ id: '', bezeichnung: '', min_drehmoment: '', max_drehmoment: '', kategorie: '', type: '' });
    fetchBauteile();
  };

  const handleDelete = async (id) => {
    await fetch(`https://gt6.at/admin/manage_bauteile.php?action=delete&id=${id}`, {
      method: 'POST'
    });
    fetchBauteile();
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} style={{ padding: '2rem', marginTop: '2rem' }}>
        <Typography variant="h4" component="h1" gutterBottom>Admin-Bereich</Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            label="Bezeichnung"
            name="bezeichnung"
            value={formData.bezeichnung}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Min. Drehmoment (kgf·m)"
            name="min_drehmoment"
            value={formData.min_drehmoment}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Max. Drehmoment (kgf·m)"
            name="max_drehmoment"
            value={formData.max_drehmoment}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Type"
            name="type"
            value={formData.type}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Kategorie"
            name="kategorie"
            value={formData.kategorie}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <Button variant="contained" color="primary" type="submit">
            {formData.id ? 'Aktualisieren' : 'Hinzufügen'}
          </Button>
        </form>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Bezeichnung</TableCell>
              <TableCell>Min. (Nm)</TableCell>
              <TableCell>Max. (Nm)</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Kategorie</TableCell>
              <TableCell>Aktionen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bauteile.map((bauteil) => (
              <TableRow key={bauteil.id}>
                <TableCell>{bauteil.bezeichnung}</TableCell>
                <TableCell>{(bauteil.min_drehmoment * 9.80665).toFixed(2)} Nm</TableCell> {/* Min-Drehmoment in Nm */}
                <TableCell>{(bauteil.max_drehmoment * 9.80665).toFixed(2)} Nm</TableCell> {/* Max-Drehmoment in Nm */}
                <TableCell>{bauteil.type}</TableCell>
                <TableCell>{bauteil.kategorie}</TableCell>
                <TableCell>
                  <Button onClick={() => setFormData(bauteil)}>Bearbeiten</Button>
                  <Button onClick={() => handleDelete(bauteil.id)}>Löschen</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Container>
  );
};

export default Admin;
