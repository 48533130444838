import React, { useState, useEffect } from 'react';
import { Container, TextField, List, ListItem, ListItemText, Typography, Grid, Paper } from '@mui/material';

import logo from './gt6.svg'; // Pfad zu deiner SVG-Datei

function AppContent() {
  const [search, setSearch] = useState('');
  const [bauteile, setBauteile] = useState([]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const fetchBauteile = async () => {
    try {
      const response = await fetch(`https://gt6.at/get_bauteile.php?search=${search}`);
      const data = await response.json();
      setBauteile(data);
    } catch (error) {
      console.error('Fehler beim Abrufen der Daten:', error);
    }
  };

  useEffect(() => {
    if (search !== '') {
      fetchBauteile();
    } else {
      setBauteile([]);
    }
  }, [search]);

  return (
    <Container maxWidth="md">
      <Paper elevation={3} style={{ padding: '2rem', marginTop: '2rem' }}>
        <div style={{ textAlign: 'center' }}>
          <img src={logo} alt="Logo" width="50%" />
        </div>
        <Typography variant="h4" component="h2"
        sx={{ fontSize: '1.5rem' }}
        gutterBottom>
          GT6 torque
        </Typography>
        
        <TextField
          fullWidth
          label="Bauteil suchen..."
          variant="outlined"
          value={search}
          onChange={handleSearch}
          margin="normal"
          sx={{ marginBottom: 2, backgroundColor: '#f9f9f9' }}
        />
        
        <List>
          {bauteile.length === 0 ? (
            <Typography variant="subtitle1" component="p" color="textSecondary">
              Keine Bauteile gefunden
            </Typography>
          ) : (
            bauteile.map((item, index) => (
              <ListItem key={index} divider>
                <ListItemText
                  primary={`${item.bezeichnung} (${item.kategorie})`}
                  secondary={`Drehmoment: ${(item.min_drehmoment * 9.80665).toFixed(2)} - ${(item.max_drehmoment * 9.80665).toFixed(2)} Nm - Type: ${item.type}`}
                />
              </ListItem>
            ))
          )}
        </List>
      </Paper>
    </Container>
  );
}

export default AppContent;
