import React, { createContext, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    isAuthenticated: false,
    username: '',
    password: ''
  });

  const login = (username, password) => {
    setAuth({
      isAuthenticated: true,
      username,
      password
    });
  };

  const logout = () => {
    setAuth({
      isAuthenticated: false,
      username: '',
      password: ''
    });
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
